<template>
	<div class="header-box">
		<div class="header-bg">
			<el-row>
				<el-col :span="12" class="header-logo">
					<img :src="`${baseUrl}img/logon.png`">
					<span>非法集资监测预警平台</span>
				</el-col>
				<el-col :span="12">
					<div class="grid-content bg-purple">
						<el-autocomplete class="inline-input" v-model="searchAll" :fetch-suggestions="querySearch" placeholder="请输入企业名称" :trigger-on-focus="false" @select="handleSelect" clearable style="width: 250px;float: left;">
							<i slot="prefix" class="el-input__icon el-icon-search"></i>
						</el-autocomplete>
						<span class="fl" style="margin:0 20px;font-size: 20px;">
							<b class="iconfont icon-user"></b>
							&nbsp;
							<b>{{usersName}}</b>
						</span>
						<el-badge :value="XTLDtotalItems" :max="99" class="item">
							<span class="iconfont icon-weidu" style="font-size: 20px;cursor: pointer;" @click="drawerXTLDFn"></span>
						</el-badge>
						<el-badge :value="totalItems" :max="99" class="item">
							<span class="iconfont icon-ling" style="font-size: 20px;cursor: pointer;" @click="drawerWarningFn"></span>
						</el-badge>
						<el-dropdown trigger="click">
							<span class="iconfont icon-huanfu colorTab"></span>
							<el-dropdown-menu slot="dropdown">
								<div v-for="(item,index) in skinData" :key="index" @click="skinTabFn(item,index)">
									<el-dropdown-item>{{item.name}}</el-dropdown-item>
								</div>
							</el-dropdown-menu>
						</el-dropdown>
						<el-dropdown trigger="click">
							<span class="iconfont icon-tuichu1"></span>
							<el-dropdown-menu slot="dropdown">
								<div @click="backLogin">
									<el-dropdown-item>退出</el-dropdown-item>
								</div>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
				</el-col>
			</el-row>
		</div>
		<el-drawer title="" :visible.sync="drawerWarning" direction="rtl" :with-header="false" :show-close="false">
			<div class="content-box table-box">
				<el-table border :data="tableData" :height="screenHeight" :row-style="{height:'40px'}">
					<el-table-column type="index" label="序号" width="50"></el-table-column>
					<el-table-column prop="companyName" label="企业名称"></el-table-column>
					<el-table-column prop="risk" label="预警分数" width="100"></el-table-column>
					<template slot="empty">
						<img class="data-pic" :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 100px auto;" />
					</template>
				</el-table>
			</div>
		</el-drawer>
		<el-drawer title="" :visible.sync="XTLDdrawer" direction="rtl" :with-header="false" :show-close="false">
			<div class="content-box table-box">
				<el-button plain size="small" style="float: left;margin-bottom: 10px;" @click="handleXTLDstatus">
					<span class="iconfont icon-weidudianjihou"></span> 全部标为已读
				</el-button>
				<el-table border :data="XTLDtableData" :height="screenHeight-46" :row-style="{height:'40px'}" :show-header="false">
					<el-table-column prop="description" label="">
						<template slot-scope="scope">
							<span v-if="scope.row.status==0 && scope.row.type==0" class="color-m">{{scope.row.description}}</span>
							<span v-if="scope.row.status==0 && scope.row.type==1" class="color-m" @click="handleEdit(scope.$index, scope.row)" style="cursor: pointer;">{{scope.row.description}}</span>
							<span v-if="scope.row.status==0 && scope.row.type==2" class="color-m" @click="handleEdit(scope.$index, scope.row)" style="cursor: pointer;">{{scope.row.description}}</span>
							<span v-if="scope.row.status==0 && scope.row.type==3" class="color-m" @click="handleEdit(scope.$index, scope.row)" style="cursor: pointer;">{{scope.row.description}}</span>
							<span v-if="scope.row.status==1 && scope.row.type==0">{{scope.row.description}}</span>
							<span v-if="scope.row.status==1 && scope.row.type==1" @click="handleEdit(scope.$index, scope.row)" style="cursor: pointer;">{{scope.row.description}}</span>
							<span v-if="scope.row.status==1 && scope.row.type==2" @click="handleEdit(scope.$index, scope.row)" style="cursor: pointer;">{{scope.row.description}}</span>
							<span v-if="scope.row.status==1 && scope.row.type==3" @click="handleEdit(scope.$index, scope.row)" style="cursor: pointer;">{{scope.row.description}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="createTime" label="" width="150"></el-table-column>
					<el-table-column prop="description" label="" width="50">
						<template slot-scope="scope">
							<span v-if="scope.row.type==0">
								<i class="iconfont icon-xiangqing"></i>
							</span>
							<span v-if="scope.row.type==1" class="color-m" @click="handleEdit(scope.$index, scope.row)" style="cursor: pointer;">
								<i class="iconfont icon-xiangqing" title="行政案件详情"></i>
							</span>
							<span v-if="scope.row.type==2" class="color-m" @click="handleEdit(scope.$index, scope.row)" style="cursor: pointer;">
								<i class="iconfont icon-xiangqing" title="刑事案件详情"></i>
							</span>
							<span v-if="scope.row.type==3" class="color-m" @click="handleEdit(scope.$index, scope.row)" style="cursor: pointer;">
								<i class="iconfont icon-xiangqing" title="小程序详情"></i>
							</span>
						</template>
					</el-table-column>
					<template slot="empty">
						<img class="data-pic" :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 100px auto;" />
					</template>
				</el-table>
			</div>
		</el-drawer>
		
		<el-dialog title="行政案件信息详情" :visible.sync="dialogVisibleXZ" width="80%" class="madal-box-anjian" :close-on-click-modal="false">
			<div :style="ruleFormXZ.zeroStatus == 0 ? 'width: 100%;height: 600px;' : 'width: 100%;'">
				<el-scrollbar style="height: 100%;width:100%">
					<el-form :model="ruleFormXZ" ref="ruleFormBJ" label-width="200px" class="demo-ruleForm">
						<h4 class="title-h4">基本信息</h4>
						<el-row :gutter="30">
							<el-col :span="24">
								<el-form-item>
									<span slot="label">案件名称<font style="color: #F56C6C;">*</font></span>
									<el-row :gutter="0" v-show="ruleFormXZ.zeroStatus == 0">
										<el-col :span="1" style="text-align: left;line-height: 34px;margin-bottom: 10px;color: #ccc;">企业：</el-col>
										<el-col :span="23" style="text-align: left;line-height: 30px;">	
											<span>{{ruleFormXZ.casenameCompany}}</span>
										</el-col>
									</el-row>
									<el-row :gutter="0" v-show="ruleFormXZ.zeroStatus == 0">
										<el-col :span="1" style="text-align: left;line-height: 34px;margin-bottom: 10px;color: #ccc;">个人：</el-col>
										<el-col :span="23" style="text-align: left;line-height: 30px;">	
											<span>{{ruleFormXZ.casenamePerson}}</span>
										</el-col>
									</el-row>
									<el-row :gutter="0" v-show="ruleFormXZ.zeroStatus == 0">
										<el-col :span="1" style="text-align: left;line-height: 34px;color: #ccc;">其他：</el-col>
										<el-col :span="23" style="text-align: left;line-height: 30px;">	
											<span>{{ruleFormXZ.casenameOther}}</span>
										</el-col>
									</el-row>
									<el-row :gutter="0" v-show="ruleFormXZ.zeroStatus == 1">
										<el-col :span="1" style="text-align: left;line-height: 43px;color: #ccc;">其他：</el-col>
										<el-col :span="11" style="text-align: left;">	
											<span>{{ruleFormXZ.casenameOther}}</span>
										</el-col>
									</el-row>
								</el-form-item>
							</el-col>
						</el-row>
						<div v-show="ruleFormXZ.zeroStatus == 0">
							<el-row :gutter="30">
								<el-col :span="12">
									<el-form-item prop="jbxxSshy">
										<span slot="label">行业领域<font style="color: #F56C6C;">*</font></span>
										<span>{{ruleFormXZ.jbxxSshy}}</span>
									</el-form-item>
								</el-col>
								<el-col :span="12">	
									<el-form-item prop="jbxxSshyqt" v-show="ruleFormXZ.jbxxSshy == '其他（需按《国民经济行业分类》（GB/T4754-2017）行业分类进行备注）'">
										<span slot="label">其他行业<font style="color: #F56C6C;">*</font></span>
										<span>{{ruleFormXZ.jbxxSshy}}</span>
									</el-form-item>
								</el-col>
							</el-row>
							<el-row :gutter="30">
								<el-col :span="12">
									<el-form-item label="集资渠道" prop="jbxxJzqd">
										<span slot="label">集资渠道<font style="color: #F56C6C;">*</font></span>
										<span>{{ruleFormXZ.jbxxJzqd}}</span>
									</el-form-item>
								</el-col>
								<el-col :span="12">	
									<el-form-item prop="eventStatus">
										<span slot="label">案件类型<font style="color: #F56C6C;">*</font></span>
										<span v-if="ruleFormXZ.eventStatus == 0">新发案件</span>
										<span v-if="ruleFormXZ.eventStatus == 1">存量案件</span>
									</el-form-item>
								</el-col>
							</el-row>
							<el-row :gutter="30">
								<el-col :span="12">
									<el-form-item label="" prop="jbxxLjjzje">
										<span slot="label">集资金额<font style="color: #F56C6C;">*</font></span>
										<span v-if="ruleFormXZ.jbxxLjjzje != ''">{{ruleFormXZ.jbxxLjjzje}} 万元</span>
									</el-form-item>
									<el-form-item label="" prop="jbxxLsjzrs">
										<span slot="label">参与集资人数<font style="color: #F56C6C;">*</font></span>
										<span v-if="ruleFormXZ.jbxxLsjzrs != ''">{{ruleFormXZ.jbxxLsjzrs}} 人</span>
									</el-form-item>
									<el-form-item label="" prop="fzczdxzjgssq">
										<span slot="label">行政机关所在地<font style="color: #F56C6C;">*</font></span>
										<span>{{ruleFormXZ.provinceName}} {{ruleFormXZ.cityName}}</span>
									</el-form-item>
								</el-col>
								<el-col :span="12">	
									<el-form-item label="未清退金额" prop="jbxxWdfje">
										<span slot="label">未清退金额<font style="color: #F56C6C;">*</font></span>
										<span v-if="ruleFormXZ.jbxxWdfje != ''">{{ruleFormXZ.jbxxWdfje}} 万元</span>
									</el-form-item>
									<el-form-item label="未清退人数" prop="jbxxJzrs">
										<span slot="label">未清退人数<font style="color: #F56C6C;">*</font></span>
										<span v-if="ruleFormXZ.jbxxJzrs != ''">{{ruleFormXZ.jbxxJzrs}} 人</span>
									</el-form-item>
									<el-form-item label="负责处置的行政机关所在地" prop="xzczFzczdxzjg">
										<span slot="label">负责处置的行政机关所在地<font style="color: #F56C6C;">*</font></span>
										<span>{{ruleFormXZ.xzczFzczdxzjg}}</span>
									</el-form-item>
								</el-col>
							</el-row>
							<h4 class="title-h4">调查认定</h4>		
							<el-row :gutter="30">
								<el-col :span="12">
									<el-form-item label="" prop="xzczLasj">
										<span slot="label">立案时间<font style="color: #F56C6C;">*</font></span>
										<span>{{ruleFormXZ.xzczLasj}}</span>
									</el-form-item>
									<el-form-item label="行政认定" prop="xzczXzrdjg">
										<span v-if="ruleFormXZ.xzczXzrdjg==1">是</span>	
										<span v-if="ruleFormXZ.xzczXzrdjg==0">否</span>
									</el-form-item>
								</el-col>
								<el-col :span="12">	
									<el-form-item label="限制出境" prop="xzcsSfxzcj">
										<span v-if="ruleFormXZ.xzcsSfxzcj==1">是</span>	
										<span v-if="ruleFormXZ.xzcsSfxzcj==0">否</span>	
									</el-form-item>
								</el-col>
							</el-row>
							<h4 class="title-h4">移送公安</h4>		
							<el-row :gutter="30">
								<el-col :span="12">
									<el-form-item label="" prop="xzcsSfysgaj">
										<span slot="label">移送公安<font style="color: #F56C6C;">*</font></span>
										<span v-if="ruleFormXZ.xzcsSfysgaj==1">是</span>	
										<span v-if="ruleFormXZ.xzcsSfysgaj==0">否</span>	
									</el-form-item>
								</el-col>
								<el-col :span="12">	
									<el-form-item label="移送时间" prop="xzcsYsgajsj">
										<span>{{ruleFormXZ.xzcsYsgajsj}}</span>
									</el-form-item>
								</el-col>
							</el-row>
							<h4 class="title-h4">行政处罚</h4>		
							<el-row :gutter="30">
								<el-col :span="12">
									<el-form-item label="作出处罚时间" prop="xzcfCfsj">
										<span>{{ruleFormXZ.xzcfCfsj}}</span>	
									</el-form-item>
								</el-col>
								<el-col :span="12">	
									<p style="text-align: left;padding-left: 20px;line-height: 30px;" class="el-form-item-ff">
										<el-form-item label="是否警告" prop="xzcfCfsj">
											<span v-if="ruleFormXZ.xzcfSfjg==1 && ruleFormXZ.xzcfJgrs!=null">是 并警告{{ruleFormXZ.xzcfJgrs}}人</span>	
											<span v-if="ruleFormXZ.xzcfSfjg==1 && ruleFormXZ.xzcfJgrs==null">是</span>	
											<span v-if="ruleFormXZ.xzcfSfjg==0">否</span>	
										</el-form-item>
									</p>
									<p style="text-align: left;padding-left: 20px;line-height: 30px;" class="el-form-item-ff">
										<el-form-item label="是否处罚" prop="xzcfCfsj">
											<span v-if="ruleFormXZ.xzcfSffk==1 && ruleFormXZ.xzcfFk!=null">是 并处罚{{ruleFormXZ.xzcfFk}}万元</span>	
											<span v-if="ruleFormXZ.xzcfSffk==1 && ruleFormXZ.xzcfFk==null">是</span>	
											<span v-if="ruleFormXZ.xzcfSffk==0">否</span>	
										</el-form-item>
									</p>
									<p style="text-align: left;padding-left: 20px;line-height: 30px;" class="el-form-item-ff">
										<el-form-item label="是否责令停产停业" prop="xzcfCfsj">
											<span v-if="ruleFormXZ.xzcfTcty==1">是</span>	
											<span v-if="ruleFormXZ.xzcfTcty==0">否</span>	
										</el-form-item>
									</p>
									<p style="text-align: left;padding-left: 20px;line-height: 30px;" class="el-form-item-ff">
										<el-form-item label="吊销许可证/营业执照或注册登记证书" prop="xzcfCfsj">
											<span v-if="ruleFormXZ.xzcfSfdx==1">是</span>	
											<span v-if="ruleFormXZ.xzcfSfdx==0">否</span>	
										</el-form-item>
									</p>
								</el-col>
							</el-row>
							<h4 class="title-h4">资金清退</h4>		
							<el-row :gutter="30">
								<el-col :span="12">
									<el-form-item label="已清退资金金额" prop="xzczYqtje">
										<span v-if="ruleFormXZ.xzczYqtje != ''">{{ruleFormXZ.xzczYqtje}} 万元</span>
									</el-form-item>
								</el-col>
							</el-row>
						</div>
					</el-form>
				</el-scrollbar>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="caseLinkXZ">跳 转</el-button>
			</span>
		</el-dialog>
		<el-dialog title="刑事案件信息详情" :visible.sync="dialogVisibleXS" width="80%" class="madal-box-anjian" :close-on-click-modal="false">
			<div :style="ruleFormXS.zeroStatus == 0 ? 'width: 100%;height: 600px;' : 'width: 100%;'">
				<el-scrollbar style="height: 100%;width:100%">
					<el-form :model="ruleFormXS" ref="ruleFormBJ" label-width="160px" class="demo-ruleForm">
						<h4 class="title-h4">基本信息</h4>
						<el-row :gutter="30">
							<el-col :span="12">
								<el-form-item>
									<span slot="label">案件名称<font style="color: #F56C6C;">*</font></span>
									<el-row :gutter="0" v-show="ruleFormXS.zeroStatus == 0">
										<el-col :span="2" style="text-align: left;line-height: 44px;margin-bottom: 10px;color: #ccc;">企业：</el-col>
										<el-col :span="22" style="text-align: left;">	
											<span>{{ruleFormXS.jbxxQymc}}</span>
										</el-col>
									</el-row>
									<el-row :gutter="0" v-show="ruleFormXS.zeroStatus == 0">
										<el-col :span="2" style="text-align: left;line-height: 43px;margin-bottom: 10px;color: #ccc;">个人：</el-col>
										<el-col :span="22" style="text-align: left;">	
											<span>{{ruleFormXS.jbxxGr}}</span>
										</el-col>
									</el-row>
									<el-row :gutter="0">
										<el-col :span="2" style="text-align: left;line-height: 43px;color: #ccc;">其他：</el-col>
										<el-col :span="22" style="text-align: left;">	
											<span>{{ruleFormXS.jbxxQt}}</span>
										</el-col>
									</el-row>
								</el-form-item>
							</el-col>
							<el-col :span="12" v-show="ruleFormXS.zeroStatus == 0">
								<el-form-item label="" prop="jbxxJzrs">
									<span slot="label">参与集资人数<font style="color: #F56C6C;">*</font></span>
									<span v-if="ruleFormXS.jbxxJzrs != ''">{{ruleFormXS.jbxxJzrs}} 人</span>
								</el-form-item>
								<el-form-item label="" prop="czqkLasj">
									<span slot="label">立案时间<font style="color: #F56C6C;">*</font></span>
									<span>{{ruleFormXS.czqkLasj}}</span>
								</el-form-item>
								<el-form-item label="" prop="fzczdxzjgssq">
									<span slot="label">立案地<font style="color: #F56C6C;">*</font></span>
									<span>{{ruleFormXS.provinceName}} {{ruleFormXS.cityName}}</span>
								</el-form-item>
							</el-col>
						</el-row>
						<div v-show="ruleFormXS.zeroStatus == 0">
							<el-row :gutter="30">
								<el-col :span="12">
									<el-form-item label="集资渠道" prop="jbxxJzqd">
										<span slot="label">集资渠道<font style="color: #F56C6C;">*</font></span>
										<span>{{ruleFormXS.jbxxJzqd}}</span>
									</el-form-item>
									<el-form-item label="" prop="jbxxWdfje">
										<span slot="label">涉案金额<font style="color: #F56C6C;">*</font></span>
										<span v-if="ruleFormXS.jbxxWdfje != ''">{{ruleFormXS.jbxxWdfje}} 万元</span>
									</el-form-item>
									<el-form-item prop="jbxxSshy">
										<span slot="label">行业领域<font style="color: #F56C6C;">*</font></span>
										<span>{{ruleFormXS.jbxxSshy}}</span>
									</el-form-item>
									<el-form-item prop="eventStatus">
										<span slot="label">案件类型<font style="color: #F56C6C;">*</font></span>
										<span v-if="ruleFormXZ.eventStatus == 0">新发案件</span>
										<span v-if="ruleFormXZ.eventStatus == 1">存量案件</span>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="立案罪名" prop="czqkLazm">
										<span slot="label">立案罪名<font style="color: #F56C6C;">*</font></span>
										<span>{{ruleFormXS.czqkLazm}}</span>
									</el-form-item>
									<el-form-item label="是否跨省案件" prop="jbxxSfks">
										<span slot="label">是否跨省案件<font style="color: #F56C6C;">*</font></span>
										<div style="text-align: left;">
											<span v-if="ruleFormXS.jbxxSfks == 0">否</span>
											<span v-if="ruleFormXS.jbxxSfks == 1">是</span>
										</div>
									</el-form-item>
									<el-form-item prop="jbxxSshyqt" v-show="ruleFormXS.jbxxSshy == '其他（需按《国民经济行业分类》（GB/T4754-2017）行业分类进行备注）'">
										<span slot="label">其他行业<font style="color: #F56C6C;">*</font></span>
										<span>{{ruleFormXS.jbxxSshyqt}}</span>
									</el-form-item>
								</el-col>
							</el-row>
						</div>
						<div v-show="ruleFormXS.zeroStatus == 0">
							<h4 class="title-h4">结案情况</h4>		
							<el-row :gutter="30">
								<el-col :span="12">
									<el-form-item label="判决时间" prop="czqkPjsj">
										<span>{{ruleFormXS.czqkPjsj}}</span>
									</el-form-item>
									<el-form-item label="判决罪名" prop="czqkPjzm">
										<span>{{ruleFormXS.czqkPjzm}}</span>
									</el-form-item>
									<el-form-item label="判决文书号" prop="czqkAh">
										<span>{{ruleFormXS.czqkAh}}</span>
									</el-form-item>
									<el-form-item label="判决主要单位">
										<el-row :gutter="0">
											<el-col :span="2" style="text-align: left;line-height: 44px;margin-bottom: 10px;color: #ccc;">企业：</el-col>
											<el-col :span="22" style="text-align: left;">	
												<span>{{ruleFormXS.jaqkZspjqy}}</span>
											</el-col>
										</el-row>
										<el-row :gutter="0">
											<el-col :span="2" style="text-align: left;line-height: 30px;margin-bottom: 10px;color: #ccc;">个人：</el-col>
											<el-col :span="22" style="text-align: left;">	
												<span>{{ruleFormXS.jaqkZspjgr}}</span>
											</el-col>
										</el-row>
									</el-form-item>
								</el-col>
								<el-col :span="12">	
									<el-form-item label="已清退资金金额" prop="czqkYqtzjze">
										<span v-if="ruleFormXS.czqkYqtzjze != ''">{{ruleFormXS.czqkYqtzjze}} 万元</span>
									</el-form-item>
									<el-form-item label="结案时间" prop="czqkBjsj">
										<span>{{ruleFormXS.czqkBjsj}}</span>
									</el-form-item>
									<el-form-item label="其他办结情形时间" prop="czqkZzslsj">
										<span>{{ruleFormXS.czqkZzslsj}}</span>
									</el-form-item>
									<el-form-item label="其他办结情形" prop="czqkQtbjqx">
										<span>{{ruleFormXS.czqkQtbjqx}}</span>
									</el-form-item>
									<el-form-item label="应当清退资金金额" prop="czqkYdqtzjze">
										<span v-if="ruleFormXS.czqkYdqtzjze != ''">{{ruleFormXS.czqkYdqtzjze}} 万元</span>
									</el-form-item>
								</el-col>
							</el-row>
							<el-row :gutter="30">
								<el-col :span="24">
									<el-form-item label="备注" prop="czqkBz">
										<span>{{ruleFormXS.czqkBz}}</span>
									</el-form-item>
								</el-col>
							</el-row>
						</div>
					</el-form>
				</el-scrollbar>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="caseLinkXS">跳 转</el-button>
			</span>
		</el-dialog>
		<el-dialog title="小程序举报详情" :visible.sync="dialogVisibleXCX" width="80%" class="madal-box-anjian" :close-on-click-modal="false">
			<div style="width: 100%;height: 640px;">
				<el-scrollbar style="height: 100%;width:100%;">
					<el-form :model="ruleFormXCX" label-width="180px" class="demo-ruleForm" style="padding-right: 15px;">
						<h4 class="title-h4">举报对象信息</h4>
						<el-row :gutter="0">
							<el-col :span="8">
								<el-form-item label="举报对象名称" prop="reportedName">
									<span>{{ruleFormXCX.reportedName}}</span>
								</el-form-item>
								<el-form-item label="举报对象类型" prop="reportType">
									<span>{{ruleFormXCX.reportType}}</span>
								</el-form-item>
								<el-form-item label="举报对象所在区域" prop="diqu">
									<span>{{ruleFormXCX.reportedName}}</span>
								</el-form-item>
								<el-form-item label="举报对象详细地址" prop="reportAddr">
									<span>{{ruleFormXCX.reportAddr}}</span>
								</el-form-item>
								<el-form-item label="举报对象所属行业" prop="reportIndustry">
									<span>{{ruleFormXCX.reportIndustry}}</span>
								</el-form-item>
								<el-form-item label="集资类型" prop="financingType">
									<span>{{ruleFormXCX.financingType}}</span>
								</el-form-item>
								<el-form-item label="投入金额(元)" prop="investCapital">
									<span>{{ruleFormXCX.investCapital}}</span>
								</el-form-item>
								<el-form-item label="收益类型" prop="revenueType">
									<span>{{ruleFormXCX.revenueType}}</span>
								</el-form-item>
								<el-form-item label="利率" prop="rate">
									<span>{{ruleFormXCX.rate}}</span>
								</el-form-item>
							</el-col>
							<el-col :span="8">	
								<el-form-item label="关联APP名称" prop="relationalAppName">
									<span>{{ruleFormXCX.relationalAppName}}</span>
								</el-form-item>
								<el-form-item label="关联网站名称" prop="relationalWebsiteName">
									<span>{{ruleFormXCX.relationalWebsiteName}}</span>
								</el-form-item>
								<el-form-item label="关联联系公众号" prop="relationalPublicAccount">
									<span>{{ruleFormXCX.relationalPublicAccount}}</span>
								</el-form-item>
								<el-form-item label="关联联系微信号" prop="relationalWeixinAccount">
									<span>{{ruleFormXCX.relationalWeixinAccount}}</span>
								</el-form-item>
								<el-form-item label="关联联系QQ号" prop="relationalQqAccount">
									<span>{{ruleFormXCX.relationalQqAccount}}</span>
								</el-form-item>
								<el-form-item label="关联联系电话" prop="relationalPhoneNumber">
									<span>{{ruleFormXCX.relationalPhoneNumber}}</span>
								</el-form-item>
								<el-form-item label="关联主体工商" prop="relationalBusinessInfo">
									<span>{{ruleFormXCX.relationalBusinessInfo}}</span>
								</el-form-item>
								<el-form-item label="发案时间" prop="caseTime">
									<span>{{ruleFormXCX.caseTime}}</span>
								</el-form-item>
							</el-col>
							<el-col :span="8">	
								<el-form-item label="是否涉及互联网" prop="involvingInternet">
									<span>{{ruleFormXCX.involvingInternet}}</span>
								</el-form-item>
								<el-form-item label="是否承诺保本保息" prop="promisePrincipal">
									<span v-if="ruleFormXCX.promisePrincipal == 0">否</span>
									<span v-if="ruleFormXCX.promisePrincipal == 1">是</span>
								</el-form-item>
								<el-form-item label="已知集资规模(万元)" prop="financingMoney">
									<span>{{ruleFormXCX.financingMoney}}</span>
								</el-form-item>
								<el-form-item label="已知参与人数(人)" prop="participantsNumber">
									<span>{{ruleFormXCX.participantsNumber}}</span>
								</el-form-item>
								<el-form-item label="已知最早开始时间" prop="financingStartTime">
									<span>{{ruleFormXCX.financingStartTime}}</span>
								</el-form-item>
								<el-form-item label="已知最早暴雷时间" prop="financingBreakTime">
									<span>{{ruleFormXCX.financingBreakTime}}</span>
								</el-form-item>
								<el-form-item label="是否跨省" prop="transprovincial">
									<span v-if="ruleFormXCX.transprovincial == 0">否</span>
									<span v-if="ruleFormXCX.transprovincial == 1">是</span>
								</el-form-item>
								<el-form-item label="已知涉及地区" prop="relationalArea">
									<span>{{ruleFormXCX.relationalArea}}</span>
								</el-form-item>
							</el-col>
						</el-row>
						<el-form-item label="具体情况说明" prop="remark">
							<span>{{ruleFormXCX.remark}}</span>
						</el-form-item>
						<el-form-item label="风险标签" prop="riskLebal">
							<span>{{ruleFormXCX.riskLebal}}</span>
						</el-form-item>
						<h4 class="title-h4">举报人信息</h4>
						<el-row :gutter="0">
							<el-col :span="24">
								<el-form-item label="是否实名" prop="anonymity" style="width: 33.3%;float: left;">
									<span v-if="ruleFormXCX.anonymity == 0">匿名</span>
									<span v-if="ruleFormXCX.anonymity == 1">实名</span>
								</el-form-item>
								<el-form-item label="举报时间" prop="reportTime" style="width: 33.3%;float: left;">
									<span>{{ruleFormXCX.reportTime}}</span>
								</el-form-item>
							</el-col>
							<el-col :span="24">
								<el-form-item label="举报人姓名" prop="informer" style="width: 33.3%;float: left;">
									<span>{{ruleFormXCX.informer}}</span>
								</el-form-item>
								<el-form-item label="举报人身份证号" prop="informerIdCard" style="width: 33.3%;float: left;">
									<span>{{ruleFormXCX.informerIdCard}}</span>
								</el-form-item>
								<el-form-item label="举报人手机号" prop="informerPhoneNumber" style="width: 33.3%;float: left;">
									<span>{{ruleFormXCX.informerPhoneNumber}}</span>
								</el-form-item>
							</el-col>
							<el-col :span="24">
								<el-form-item label="举报人邮箱" prop="informerEmail" style="width: 33.3%;float: left;">
									<span>{{ruleFormXCX.informerEmail}}</span>
								</el-form-item>
								<el-form-item label="举报人QQ号" prop="informerQqAccount" style="width: 33.3%;float: left;">
									<span>{{ruleFormXCX.informerQqAccount}}</span>
								</el-form-item>
								<el-form-item label="举报人微信号" prop="informerWeixinAccount" style="width: 33.3%;float: left;">
									<span>{{ruleFormXCX.informerWeixinAccount}}</span>
								</el-form-item>
							</el-col>
						</el-row>
						<h4 class="title-h4">附件</h4>
						<p v-for="(item,index) in ruleFormXCX.imageIds" :key="index" style="line-height: 40px;text-align: left;padding-left: 95px;">
							<a :href="urlStr+item" target="_blank">{{urlStr+item}}</a>
						</p>
					</el-form>
				</el-scrollbar>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="caseLinkXCX">跳 转</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
export default {
	name: 'header-box',
	props: {
		usersName: String,
	},
	data() {
		return {
			baseUrl: this.$pulick.baseUrl, //默认路径目录  加载静态文件img
			searchAll: "",
			searchAllId: "",
			skinData: [
				{name: '白',value: "white"},
				{name: '蓝',value: "blue"},
			],
			drawerWarning: false,
			screenHeight: document.body.clientHeight - 35,
			tableData: [], //列表  数组
			totalItems: 0, //列表 总数
			XTLDdrawer: false,
			XTLDtableData: [], //列表  数组
			XTLDtotalItems: 0, //列表 总数
			dialogVisibleXZ: false, //是否打开查看弹框
			ruleFormXZ: {},
			dialogVisibleXS: false, //是否打开查看弹框
			ruleFormXS: {},
			dialogVisibleXCX: false, //是否打开查看弹框
			ruleFormXCX: {},
		}
	},
	created() {
		this.getNoticeList(); //获取预警信息
		this.getXTLDList(); //协同联动 未读信息
	},
	methods: {
		//返回  登录页面
		backLogin() {
			this.$confirm('此操作将登录页面, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				//清除认证信息等存储   返回登录页面
				sessionStorage.clear();
				this.$router.push({
					path: '/'
				});
			}).catch(() => {

			});
		},
		//一键换肤
		skinTabFn(item, index) {
			let user_xjfj = JSON.parse(sessionStorage.getItem("user_xjfj"));
			user_xjfj['skinInfo'] = item.value;
			sessionStorage.setItem("user_xjfj", JSON.stringify(user_xjfj));
			document.getElementById("skinStyleLink").href = 'css/style-' + item.value + '.css';
		},
		//模糊提示 功能
		querySearch(queryString, cb) {
			this.$provider.get('/wg-ifrRisk/monitor/getMatchCompanyNameList', {
				params: {
					companyName: this.searchAll
				}
			}).then(res => {
				let restaurants = [];
				res.data.filter((item, index) => {
					restaurants.push({
						value: item.companyName,
						id: item.id,
					})
				})
				cb(restaurants); // 调用 callback 返回建议列表的数据
			})
		},
		createFilter(queryString) {
			return (restaurant) => {
				return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
			};
		},
		handleSelect(item) {
			this.searchAll = item.value; //选中提示   填入
			this.searchAllId = item.id; //选中提示   填入
			const icardUrl = this.$router.resolve({
				path: '/institutionalPortraitInfo',
				query: {
					name: item.value,
					id: item.id
				}
			})
			window.open(icardUrl.href, '_blank'); //跳转新页面
		},
		//打开  预警弹框  事件
		drawerWarningFn() {
			this.drawerWarning = true;
			this.getNoticeList(); //获取预警信息
		},
		//获取预警信息
		getNoticeList() {
			this.$provider.get('/wg-ifrRisk/overallSituation/getNoticeList', {
				params: {
					provinceName: JSON.parse(sessionStorage.getItem("user_xjfj")).provinceName != "null" ? JSON.parse(sessionStorage.getItem("user_xjfj")).provinceName : "",
					cityName: JSON.parse(sessionStorage.getItem("user_xjfj")).cityName ? JSON.parse(sessionStorage.getItem("user_xjfj")).cityName : "",
				}
			}).then(res => {
				this.tableData = res.data;
				this.totalItems = res.data.length;
			})
		},
		//打开  协同联动 未读信息   弹框  事件
		drawerXTLDFn() {
			this.XTLDdrawer = true;
			//this.getXTLDList(); //协同联动 未读信息
		},
		//协同联动 未读信息
		getXTLDList() {
			this.$provider.get('/wg-ifrRisk/listen/list', {}).then(res => {
				this.XTLDtableData = res.data;
			})
			this.$provider.get('/wg-ifrRisk/listen/count', {}).then(res => {
				this.XTLDtotalItems = res.data;
				//this.XTLDtotalItems = res.data == 0 ? "" : res.data;
			})
		},
		//全部标记已读
		handleXTLDstatus(){
			// let idarr = "";
			// this.XTLDtableData.filter((item,index) => {
			// 	if(index == 0){
			// 		idarr = item.id;
			// 	} else {
			// 		idarr += "," + item.id;
			// 	}
			// })
			this.$provider.get('/wg-ifrRisk/listen/alreadyRead', {}).then(res => {
				this.getXTLDList(); //协同联动 未读信息
			})
		},
		
		//详情
		handleEdit(index,item){
			if(item.type == 1){
				this.dialogVisibleXZ = true;
				this.getAdministrationDetailByEvent(item);
			} else if(item.type == 2){
				this.dialogVisibleXS = true;
				this.getCriminalDetailByEvent(item);
			} else if(item.type == 3){
				this.dialogVisibleXCX = true;
				this.getReportInfoByCode(item);
			}
			if(item.status == 0){
				this.$provider.get('/wg-ifrRisk/listen/alreadyRead', {
					params: {
						ids:item.id
					}
				}).then(res => {
					this.getXTLDList(); //协同联动 未读信息
				})
			}
		},
		getAdministrationDetailByEvent(item) {
			this.$provider.get('/wg-ifrRisk/upload/getAdministrationDetailByEvent', {
				params: {
					eventCode: item.eventCode
				}
			}).then(res => {
				this.ruleFormXZ = res.data;
			})
		},
		getCriminalDetailByEvent(item) {
			this.$provider.get('/wg-ifrRisk/upload/getCriminalDetailByEvent', {
				params: {
					eventCode: item.eventCode
				}
			}).then(res => {
				this.ruleFormXS = res.data;
			})
		},
		getReportInfoByCode(item) {
			this.$provider.get('/wg-ifrRisk/clueApp/getReportInfoByCode', {
				params: {
					eventCode: item.eventCode
				}
			}).then(res => {
				this.ruleFormXCX = res.data;
			})
		},
		caseLinkXZ(){
			this.XTLDdrawer = false;
			this.dialogVisibleXZ = false;
			//控制菜单 选中状态
			let user_xjfj = JSON.parse(sessionStorage.getItem("user_xjfj"));
			user_xjfj['defaultActive'] = 1011;
			sessionStorage.setItem("user_xjfj", JSON.stringify(user_xjfj));
			const icardUrl = this.$router.resolve({
				path: '/caseAdministrative',
				query: {
					name: this.ruleFormXZ.casename,
					cityId: this.ruleFormXZ.processCityId,
				}
			})
			window.open(icardUrl.href, '_blank'); //跳转新页面
		},
		caseLinkXS(){
			this.XTLDdrawer = false;
			this.dialogVisibleXS = false;
			//控制菜单 选中状态
			let user_xjfj = JSON.parse(sessionStorage.getItem("user_xjfj"));
			user_xjfj['defaultActive'] = 1012;
			sessionStorage.setItem("user_xjfj", JSON.stringify(user_xjfj));
			// //跳转路由   携带参数
			// this.$router.push({
			// 	path: '/caseCriminal',
			// 	query: {
			// 		name: this.ruleFormXS.caseName,
			// 	}
			// })
			// this.$router.go(0); //对 整个浏览器进行了强制重新加载
			const icardUrl = this.$router.resolve({
				path: '/caseCriminal',
				query: {
					name: this.ruleFormXS.caseName,
					cityId: this.ruleFormXS.processCityId,
				}
			})
			window.open(icardUrl.href, '_blank'); //跳转新页面
		},
		caseLinkXCX(){
			this.XTLDdrawer = false;
			this.dialogVisibleXCX = false;
			//控制菜单 选中状态
			let user_xjfj = JSON.parse(sessionStorage.getItem("user_xjfj"));
			user_xjfj['defaultActive'] = 1009;
			sessionStorage.setItem("user_xjfj", JSON.stringify(user_xjfj));
			const icardUrl = this.$router.resolve({
				path: '/clueDiscovery',
				query: {
					name: this.ruleFormXCX.reportedName,
				}
			})
			window.open(icardUrl.href, '_blank'); //跳转新页面
		},
	}
}
</script>

<style lang="stylus" scoped="scoped"></style>
<style lang="stylus">
	.madal-box-anjian .el-form-item__content{
		text-align: left
	}
	.el-form-item-ff .el-form-item{
		margin:0px
	}
	.el-form-item-ff .el-form-item .el-form-item__label{
		width: 260px!important;
		padding: 0;
	}
</style>
