<template>
	<div class="menu-box" :style="isCollapse==true?'width:64px':'width:200px'">
		<el-menu :default-active="defaultActive" class="el-menu-vertical-demo" :collapse="isCollapse" unique-opened>
			<el-submenu v-if="item.children && item.children.length" :index="item.id+''" v-for="(item,index) in menuList" :key="index">
				<template slot="title">
					<i :class="item.icon"></i>
					<span>{{item.name}}</span>
				</template>
				<el-menu-item v-for="(ietm,i) in item.children" :key="i" :index="ietm.id+''" @click="menuLink(ietm)">
					<i class="el-icon-arrow-right"></i>
					<span>{{ietm.name}}</span>
				</el-menu-item>
			</el-submenu>
			<el-menu-item v-else :index="item.id+''" @click="menuLink(item)">
				<i :class="item.icon"></i>
				<span>{{item.name}}</span>
			</el-menu-item>
		</el-menu>
		<el-button type="primary" size="mini" @click="handleCloseOpen" class="handleCloseOpen">
			<span class="iconfont icon-ic_shensuo"></span>
		</el-button>
	</div>
</template>

<script>
export default {
	name: "menu-box",
	props: {
		menuList: {
			type: Array,
		}
	},
	data() {
		return {
			defaultActive: JSON.parse(sessionStorage.getItem("user_xjfj")).defaultActive.toString(), //控制菜单 选中状态
			selectedOptins: [],
			isCollapse: false, //菜单缩放
		}
	},
	created() {},
	methods: {
		//菜单缩放
		handleCloseOpen(key, keyPath) {
			if (this.isCollapse == false) {
				this.isCollapse = true;
				this.$emit('isCollapseChange', true);
			} else if (this.isCollapse == true) {
				this.isCollapse = false;
				this.$emit('isCollapseChange', false);
			}
		},
		//菜单选中 跳转
		menuLink(item) {
			if (this.$route.path !== item.path) {
				//控制菜单 选中状态
				let user_xjfj = JSON.parse(sessionStorage.getItem("user_xjfj"));
				user_xjfj['defaultActive'] = item.id;
				sessionStorage.setItem("user_xjfj", JSON.stringify(user_xjfj));
				//跳转路由   携带参数
				this.$router.push({
					path: item.path,
				})
			} else {
				this.$router.go(0); //对 整个浏览器进行了强制重新加载
			}
		}
	}
}
</script>

<style lang="stylus" scoped="scoped"></style>
