<template>
	<div class="body-int">
		<myHeader :users-name="usersName"></myHeader>
		<div class="router-box">
			<myMenu :menuList="menuList" @isCollapseChange="isCollapseChangeFn"></myMenu>
			<div class="router-contanter" :style="isCollapse==true?'width: calc(100% - 64px)':'width: calc(100% - 200px)'">
				<router-view></router-view>
			</div>
		</div>
	</div>
</template>

<script>
import myHeader from '../components/my-header.vue'
import myMenu from '../components/my-menu.vue'
export default {
	components: { myHeader, myMenu },
	data() {
		return {
			isCollapse: false, //菜单缩放  控制内容宽度
			menuList: JSON.parse(sessionStorage.getItem("user_xjfj")).defaultMenuList,
			usersName: JSON.parse(sessionStorage.getItem("user_xjfj")).userName,
		}
	},
	created() {
		document.getElementById("skinStyleLink").href = 'css/style-white.css';
	},
	mounted() {},
	methods: {
		//菜单缩放  控制内容宽度
		isCollapseChangeFn(res) {
			this.isCollapse = res;
		},
	},
}
</script>

<style lang="stylus" scoped="scoped"></style>
